<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Relatório Educampo </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGerais :relatorio="relatorio" />
      <IndicadoresQualidade :relatorio="relatorio" :draft="draft" />
      <ComposicaoRebanho :relatorio="relatorio" />
      <ColetaZootecnico :relatorio="relatorio" />
      <ColetaFinanceiro :relatorio="relatorio" />
      <ManejoReprodutivo :relatorio="relatorio" />
      <AjustesNutricionais :relatorio="relatorio" />
      <PlanejamentoVolumoso :relatorio="relatorio" />
      <AcompanhamentoOrdenha :relatorio="relatorio" />
      <Sanitario :relatorio="relatorio" />
      <CriaRecria :relatorio="relatorio" />
      <Clinico :relatorio="relatorio" />
      <DiscussaoZootecnicas :relatorio="relatorio" />
      <DiscussaoEconomicos :relatorio="relatorio" />
      <DiscussaoMercado :relatorio="relatorio" />
      <PlanejamentoEstrategico :relatorio="relatorio" />
      <DeclaracaoEduCampo :relatorio="relatorio" />
      <ImagensAnexo :relatorio="relatorio" />
      <ProximaVisita :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn
            color="primary"
            @click="
              () => {
                exportRel(relatorio, true)
              }
            "
          >
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-row justify="center">
        <v-dialog v-model="dialogProgressPDF" persistent max-width="430">
          <v-card>
            <v-card-title class="text-h3"> Aguarde! </v-card-title>
            <v-card-title class="text-h3">
              Estamos preparando seu relatório.
            </v-card-title>
            <v-card-text>
              <div class="text-center">
                <v-progress-circular :size="40" indeterminate color="teal">
                </v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import PDFEducampo from '../../../../services/reports/PDFEducampo'
import { exportReport } from '../../../../utils/report'
import AcompanhamentoOrdenha from './AcompanhamentoOrdenha'
import AjustesNutricionais from './AjustesNutricionais'
import Clinico from './Clinico'
import ColetaFinanceiro from './ColetaFinanceiro'
import ColetaZootecnico from './ColetaZootecnico'
import ComposicaoRebanho from './ComposicaoRebanho'
import CriaRecria from './CriaRecria'
import DeclaracaoEduCampo from './DeclaracaoEduCampo.vue'
import DiscussaoEconomicos from './DiscussaoEconomicos'
import DiscussaoMercado from './DiscussaoMercado'
import DiscussaoZootecnicas from './DiscussaoZootecnicas'
import ImagensAnexo from './ImagensAnexo'
import IndicadoresQualidade from './IndicadoresQualidade'
import InformacoesGerais from './InformacoesGerais'
import ManejoReprodutivo from './ManejoReprodutivo'
import PlanejamentoEstrategico from './PlanejamentoEstrategico'
import PlanejamentoVolumoso from './PlanejamentoVolumoso'
import ProximaVisita from './ProximaVisita.vue'
import Sanitario from './Sanitario'

export default {
  components: {
    AcompanhamentoOrdenha,
    AjustesNutricionais,
    Clinico,
    ColetaFinanceiro,
    ColetaZootecnico,
    CriaRecria,
    ComposicaoRebanho,
    DiscussaoEconomicos,
    DiscussaoMercado,
    DiscussaoZootecnicas,
    IndicadoresQualidade,
    InformacoesGerais,
    ManejoReprodutivo,
    PlanejamentoEstrategico,
    PlanejamentoVolumoso,
    DeclaracaoEduCampo,
    ProximaVisita,
    ImagensAnexo,
    Sanitario,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogProgressPDF: false,
      loading: true,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({ path: `/${this.$user.get().role}/relatorios/geral` })
    } else {
      this.getRelatorio()
    }
  },

  methods: {
    PDFEducampo,
    getRelatorio() {
      this.api.get.relatorioccs(this.codRelatorio, this.draft).then(data => {
        this.relatorio = data
        this.loading = false
      })
    },

    async exportRel(relatorio, local) {
      if (!relatorio) relatorio = this.relatorio
      this.dialogProgressPDF = local ? true : false
      await exportReport({
        report: relatorio,
        exportReport: this.PDFEducampo,
      })
      this.dialogProgressPDF = false
    },
  },
}
</script>

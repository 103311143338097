<template>
  <v-card
    v-if="
      (relatorio.Vacinas != null && relatorio.Vacinas.length != 0) ||
      (relatorio.Pediluvio != null && relatorio.Pediluvio.length != 0) ||
      (relatorio.Vermifucacao != null && relatorio.Vermifucacao.length != 0) ||
      (relatorio.ControleCarrapatos != null &&
        relatorio.ControleCarrapatos.length != 0) ||
      relatorio.OutrasRecomendacoesManejoSanitario != '' ||
      (relatorio.ImagensManejoSanitario != null &&
        relatorio.ImagensManejoSanitario.length) ||
      relatorio.SanitarioObs ||
      relatorio.Step11Atividade1 ||
      relatorio.Step11Atividade2 ||
      relatorio.Step11VacinacaoEspecifica ||
      relatorio.Step11VacinacaoEspecificaText ||
      relatorio.Step11ControleEspecifico ||
      relatorio.Step11ControleEspecificoText ||
      relatorio.Step11OutrasAtividades ||
      relatorio.Step11OutrasAtividadesText ||
      (relatorio.sanitario &&
        (relatorio.sanitario.Vacinas ||
          relatorio.sanitario.Pediluvio ||
          relatorio.sanitario.Vermifugacao ||
          relatorio.sanitario.ControleCarrapatos ||
          relatorio.sanitario.Obs))
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Manejo sanitário</v-card-title>
    <v-card-text>
      <v-row
        v-if="
          relatorio.Step11Atividade1 ||
          relatorio.Step11Atividade2 ||
          relatorio.Step11VacinacaoEspecifica ||
          relatorio.Step11VacinacaoEspecificaText ||
          relatorio.Step11ControleEspecifico ||
          relatorio.Step11ControleEspecificoText ||
          relatorio.Step11OutrasAtividades ||
          relatorio.Step11OutrasAtividadesText ||
          relatorio.Vacinas ||
          relatorio.Pediluvio ||
          relatorio.Vermifucacao ||
          relatorio.ControleCarrapatos ||
          relatorio.OutrasRecomendacoesManejoSanitario ||
          relatorio.SanitarioObs
        "
        class="pa-3"
      >
        <v-col
          v-if="relatorio.Vacinas != null && relatorio.Vacinas.length != 0"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Vacinas </span> <br />
          <v-chip
            v-for="(vacina, index) in relatorio.Vacinas"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Vacinas"
              ><strong>Nome: </strong>{{ vacina.msMomeVacina + ' '
              }}<br /><strong>Dosagem: </strong>{{ vacina.msDosagemVacina + ' '
              }}<br /><strong>Categoria: </strong
              >{{ vacina.msCategoriaVacina + ' ' }}</span
            >
            <span v-else> {{ vacina }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.Pediluvio != null && relatorio.Pediluvio.length != 0"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Manter utilização do pedilúvio </span> <br />
          <v-chip
            v-for="(pediluvio, index) in relatorio.Pediluvio"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Pediluvio"
              ><strong>Nome do produto: </strong
              >{{ pediluvio.msNomeProdutoPediluvio + ' ' }}<br /><strong
                >Frequência: </strong
              >{{ pediluvio.msFrequenciaPediluvio + ' ' }}<br /><strong
                >Concentração: </strong
              >{{ pediluvio.msConcentracaoPediluvio + ' ' }}</span
            >
            <span v-else> {{ pediluvio }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.Vermifucacao != null && relatorio.Vermifucacao.length != 0
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Vermifugação </span> <br />
          <v-chip
            v-for="(vermifugo, index) in relatorio.Vermifucacao"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Vermifucacao"
              ><strong>Nome do produto: </strong
              >{{ vermifugo.msNomeProdutoVermifugacao + ' ' }}<br /><strong
                >Dosagem: </strong
              >{{ vermifugo.msDosagemVermifugacao + ' ' }}<br /><strong
                >Via de aplicação: </strong
              >{{ vermifugo.msViaAplicacaoVermifugacao + ' ' }}</span
            >
            <span v-else> {{ vermifugo }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ControleCarrapatos != null &&
            relatorio.Vermifucacao.length != 0
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Carrapaticidas </span> <br />
          <v-chip
            v-for="(carrapaticida, index) in relatorio.ControleCarrapatos"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.ControleCarrapatos"
              ><strong>Nome do produto: </strong
              >{{ carrapaticida.msNomeProdutoCarrapaticida + ' ' }}<br /><strong
                >Dosagem: </strong
              >{{ carrapaticida.msDosagemCarrapaticida + ' ' }}<br /><strong
                >Via de aplicação: </strong
              >{{ carrapaticida.msViaAplicacaoCarrapaticida + ' ' }}</span
            >
            <span v-else> {{ carrapaticida }} </span>
          </v-chip>
        </v-col>

        <v-col
          v-if="
            relatorio.Step11Atividade1 ||
            relatorio.Step11Atividade2 ||
            relatorio.Step11VacinacaoEspecifica ||
            relatorio.Step11ControleEspecifico ||
            relatorio.Step11OutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>

          <v-col
            v-if="
              relatorio.Step11Atividade1 == '1' &&
              relatorio.Step11Atividade1Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Implantação do calendário sanitário:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step11Atividade1Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.Step11Atividade2 == '1' &&
              relatorio.Step11Atividade2Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Acompanhamento do calendário sanitário:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step11Atividade2Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.Step11VacinacaoEspecifica == '1' &&
              relatorio.Step11VacinacaoEspecificaText != ''
            "
            cols="12"
          >
            <span class="value-title">Realização da vacinação:</span>
            <br />
            <span class="value-title">{{
              relatorio.Step11VacinacaoEspecificaText || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.Step11ControleEspecifico == '1' &&
              relatorio.Step11ControleEspecificoText != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Planos de ação para controle de doenças:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.Step11ControleEspecificoText || '-'
            }}</span>
          </v-col>
          <v-col v-if="relatorio.Step11OutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.Step11OutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.OutrasRecomendacoesManejoSanitario != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{
            relatorio.OutrasRecomendacoesManejoSanitario || '-'
          }}</span>
        </v-col>
        <v-col v-if="relatorio.SanitarioObs">
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{ relatorio.SanitarioObs || '-' }}</span>
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          relatorio.sanitario &&
          (relatorio.sanitario.Vacinas ||
            relatorio.sanitario.Pediluvio ||
            relatorio.sanitario.Vermifugacao ||
            relatorio.sanitario.ControleCarrapatos ||
            relatorio.sanitario.Obs)
        "
        class="pa-3"
      >
        <v-col
          v-if="relatorio.sanitario.Vacinas"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Vacinas </span> <br />
          <v-chip
            v-for="(vacina, index) in maskIdentify(
              relatorio.sanitario.Vacinas,
              'Nenhuma vacina inserida',
            )"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.sanitario.Vacinas"
              ><strong>Nome: </strong>{{ vacina[0] + ' ' }}<br /><strong
                >Dosagem: </strong
              >{{ vacina[1] + ' ' }}<br /><strong>Categoria: </strong
              >{{ vacina[2] + ' ' }}</span
            >
            <span v-else> {{ vacina }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.sanitario.Pediluvio"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Manter utilização do pedilúvio </span> <br />
          <v-chip
            v-for="(pediluvio, index) in maskIdentify(
              relatorio.sanitario.Pediluvio,
              'Nenhum produto inserido',
            )"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.sanitario.Pediluvio"
              ><strong>Nome do produto: </strong>{{ pediluvio[0] + ' '
              }}<br /><strong>Frequência: </strong>{{ pediluvio[1] + ' '
              }}<br /><strong>Concentração: </strong
              >{{ pediluvio[2] + ' ' }}</span
            >
            <span v-else> {{ pediluvio }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.sanitario.Vermifugacao"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Vermifugação </span> <br />
          <v-chip
            v-for="(vermifugo, index) in maskIdentify(
              relatorio.sanitario.Vermifugacao,
              'Nenhum produto inserido',
            )"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.sanitario.Vermifugacao"
              ><strong>Nome do produto: </strong>{{ vermifugo[0] + ' '
              }}<br /><strong>Dosagem: </strong>{{ vermifugo[1] + ' '
              }}<br /><strong>Via de aplicação: </strong
              >{{ vermifugo[2] + ' ' }}</span
            >
            <span v-else> {{ vermifugo }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.sanitario.ControleCarrapatos"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Carrapaticidas </span> <br />
          <v-chip
            v-for="(carrapaticida, index) in maskIdentify(
              relatorio.sanitario.ControleCarrapatos,
              'Nenhum produto inserido',
            )"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.sanitario.ControleCarrapatos"
              ><strong>Nome do produto: </strong>{{ carrapaticida[0] + ' '
              }}<br /><strong>Dosagem: </strong>{{ carrapaticida[1] + ' '
              }}<br /><strong>Via de aplicação: </strong
              >{{ carrapaticida[2] + ' ' }}</span
            >
            <span v-else> {{ carrapaticida }} </span>
          </v-chip>
        </v-col>
        <v-col v-if="relatorio.sanitario.Obs" cols="12" class="col-bordered">
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{ relatorio.sanitario.Obs || '-' }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Sanitario',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw, noneText) {
      if (raw) {
        raw = raw.split('|')
        for (const index in raw) {
          raw[index] = raw[index].split('/')
        }
      } else {
        raw = [noneText]
      }
      return raw
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>

<template>
  <v-card
    v-if="
      relatorio.ProducaoMedia ||
      relatorio.CppMedia ||
      relatorio.CcsMedia ||
      relatorio.NumeroMDO ||
      relatorio.AreaProducao
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Principais Indicadores</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Produção média do último mês: </span> <br />
          <span class="value-title">{{
            relatorio.ProducaoMedia
              ? relatorio.ProducaoMedia + ' L / Dia'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">CPP média do último mês: </span> <br />
          <span class="value-title">
            <UpdateFields
              :id="relatorio.id"
              :relatorio="relatorio"
              title="UFC/ml * 10³"
              :field="{
                key: 'cppMedia',
                property: 'CppMedia',
                label: 'CPP média do último mês',
                type: 'number',
              }"
              :draft="draft"
          /></span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">CCS média do último mês: </span> <br />
          <span class="value-title">
            <UpdateFields
              :id="relatorio.id"
              title="céls/ml * 10³"
              :relatorio="relatorio"
              :field="{
                key: 'ccsMedia',
                property: 'CcsMedia',
                label: 'CCS média do último mês',
                type: 'number',
              }"
              :draft="draft"
          /></span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Gordura média: </span> <br />
          <span class="value-title">
            <UpdateFields
              :id="relatorio.id"
              :relatorio="relatorio"
              :field="{
                key: 'gorduraMediaUltimoMes',
                property: 'GorduraMediaUltimoMes',
                label: 'Gordura média do último mês',
                prefix: '%',
                type: 'number',
              }"
              :draft="draft"
          /></span>
        </v-col>
        <v-col cols="12" sm="6" lg="4" class="col-bordered">
          <span class="key-title">Proteína média: </span> <br />
          <span class="value-title">
            <UpdateFields
              :id="relatorio.id"
              :relatorio="relatorio"
              :field="{
                key: 'proteinaMediaUltimoMes',
                property: 'ProteinaMediaUltimoMes',
                label: 'Proteína média do último mês',
                prefix: '%',
                type: 'number',
              }"
              :draft="draft"
          /></span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title"
            >Número de pessoas envolvidas no manejo de rebanho:
          </span>
          <br />
          <span class="value-title">{{
            relatorio.NumeroMDO ? relatorio.NumeroMDO : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Área para produção de leite: </span> <br />
          <span class="value-title">{{
            relatorio.AreaProducao
              ? relatorio.AreaProducao + ' ha'
              : 'Não informado'
          }}</span>
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Produtividade/DH: </span>
          <br />
          {{ calcProdutividade() }}
        </v-col>
        <v-col cols="12" sm="6" class="col-bordered">
          <span class="key-title">Produtividade / Área: </span> <br />
          <span class="value-title">{{
            relatorio.ProducaoMedia && relatorio.AreaProducao
              ? `${convertNumberBR(
                  (parseInt(relatorio.ProducaoMedia) * 365) /
                    parseInt(relatorio.AreaProducao),
                )} L / ha / ano`
              : '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import UpdateFields from '../../../../components/reports/UpdateFields.vue'
import { convertNumberBR } from '../../../../utils/masks'

export default {
  name: 'IndicadoresQualidade',

  components: {
    UpdateFields,
  },

  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },

    draft: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    convertNumberBR,
    calcProdutividade() {
      if (this.relatorio.NumeroMDO && this.relatorio.ProducaoMedia) {
        return `${convertNumberBR(
          parseInt(this.relatorio.ProducaoMedia) /
            parseInt(this.relatorio.NumeroMDO),
        )}`
      } else {
        return '-'
      }
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
